@import "~bootstrap/scss/bootstrap";
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

html {
  height: 100%;
}

body {
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 6rem;
  height: 100%;
}

#root {
  height: 100%
}

textarea {
  min-height: 80px;
  font-weight: bold;
  font-size: 1.1em !important;
  color: black !important;
}

.word-wrapper {
  width: 140px;
}

.opac {
  opacity: .5;
}

label {
  font-weight: bold;
  color: wheat;
  font-size: 1.1em;
}